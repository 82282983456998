var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items-per-page":_vm.items.length,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.created.substr(0, 10)))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.date.substr(0, 10)))])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(item.phoneNumber))])]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"100"}},[_c('v-img',{attrs:{"src":_vm.$url+'/'+item.imageUrl}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fi fi-rr-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])])]}}])})],1),_c('Pagination')],1)}
var staticRenderFns = []

export { render, staticRenderFns }